import TextField from "components/TextField";
import CustomDropDown from "components/CustomDropdown";
import { countryList } from "shared/assets/constants/commonConstants";
import { isAddressValid, isCityValid, isZipcodeValid } from "./validations";
import { IPatientAddressDetailsFormType } from "state/types/add-edit-episode-slice.type";
import { validateNumber } from "shared/methods/utilityFunctions";

type PatientAddressProps = {
  form: IPatientAddressDetailsFormType;
  setForm: (val: IPatientAddressDetailsFormType) => void;
};

const PatientAddress = ({ form, setForm }: PatientAddressProps) => {
  const { address, zipcode, city, state } = form;

  const handleAddressChange = (value: string) => {
    setForm({
      ...form,
      address: {
        ...address,
        value,
        isValid: isAddressValid(value),
      },
    });
  };

  const handleZipcodeChange = (value: string) => {
    setForm({
      ...form,
      zipcode: {
        ...zipcode,
        value,
        isValid: isZipcodeValid(value),
      },
    });
  };

  const handleCityChange = (value: string) => {
    setForm({
      ...form,
      city: {
        ...city,
        value,
        isValid: isCityValid(value),
      },
    });
  };

  const handleStateChange = (value: string) => {
    setForm({
      ...form,
      state: {
        ...state,
        value,
        isValid: true,
      },
    });
  };

  return (
    <>
      <div className="input-row-container">
        <div className="input-container" id={address.id}>
          <div className="input-wrapper">
            <TextField
              label="Address"
              type="text"
              value={address.value}
              setValue={(label, value) => handleAddressChange(value)}
              inputClassName="address-input"
              placeholder=""
              errorMessage="Length should be minimum 3 and maximum 95 characters."
              showError={!address.isDisabled && !address.isValid}
              isRequired
              disabled={address.isDisabled}
            />
          </div>
        </div>
        <div className="input-container" id={zipcode.id}>
          <div className="input-wrapper">
            <TextField
              label="Zip Code"
              type="text"
              value={zipcode.value}
              setValue={(label, value) => {
                if (value != "" && !validateNumber(value)) {
                  return;
                }
                handleZipcodeChange(value);
              }}
              inputClassName="zipcode-input"
              placeholder=""
              errorMessage="Length should be equal to 5 digits."
              showError={!zipcode.isDisabled && !zipcode.isValid}
              isRequired
              disabled={zipcode.isDisabled}
            />
          </div>
        </div>
        <div className="input-container" id={city.id}>
          <div className="input-wrapper">
            <TextField
              label="City"
              type="text"
              value={city.value}
              setValue={(label, value) => handleCityChange(value)}
              inputClassName="city-input"
              placeholder=""
              errorMessage="Length should be minimum 3 and maximum 85 characters."
              showError={!city.isDisabled && !city.isValid}
              isRequired
              disabled={city.isDisabled}
            />
          </div>
        </div>
      </div>

      <div className="input-row-container">
        <div className="input-container" id={state.id}>
          <div className="input-wrapper">
            <div className="dropdown-label">
              State
              <span className="red-color-asterisk">*</span>
            </div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={countryList}
              handleValueChanges={({ name }) => {
                handleStateChange(name);
              }}
              defaultValue={{ name: state.value }}
              idFieldName="key"
              dropDownBoxClass="state-dropdown"
              selectionClass="state-selection"
              errorMessage="Please select one from dropdown."
              showError={!state.isDisabled && !state.isValid}
              isDisabled={state.isDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientAddress;
