import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { OTPScreen } from "pages/otp-screen/otp-screen";
import { HomeLogin } from "pages/home-login/home-login.component";
import AddEditEpisodeContainer from "pages/add-edit-episode/add-edit-episode.container";
import Nav from "./Nav";
import "./styles.scss";
import TOC from "pages/toc/toc.container";
import { withRouter } from "react-router";
import { ProtectedRoute } from "./protected-route.component";
import { ResetPassword } from "pages/reset-password";
import { UnprotectedRoute } from "./unprotected-route.component";
import { Messages } from "pages/calling-messaging-logs/messages.component";
import TocDetail from "pages/toc-detail/toc-detail";
import { PatientEpisodes } from "pages/patient-episodes/patient-episodes.component";
import Dashboard from "pages/Dashboard/dashboard.component";

const NavWithRouter = withRouter(Nav);

export default function AppRoutes() {
  return (
    <Router>
      {/* NavWithRouter Contains logic for condinally rendering SideBar depending on the url location */}
      <Switch>
        <NavWithRouter>
          <Route
            exact
            path="/patient-episodes/add"
            children={<ProtectedRoute children={<AddEditEpisodeContainer />} />}
          />
          <Route
            exact
            path="/patient-episodes/edit/:intakeId"
            children={<ProtectedRoute children={<AddEditEpisodeContainer />} />}
          />
          <Route
            exact
            path="/dashboard"
            children={<ProtectedRoute children={<Dashboard />} />}
          />
          <Route
            exact
            path="/messages"
            children={<ProtectedRoute children={<Messages />} />}
          />
          <Route
            exact
            path="/patient-episodes"
            children={<ProtectedRoute children={<PatientEpisodes />} />}
          />
          <Route
            exact
            path="/toc"
            children={<ProtectedRoute children={<TOC />} />}
          />
          <Route
            exact
            path="/toc/:intakeId"
            children={<ProtectedRoute children={<TocDetail />} />}
          />
        </NavWithRouter>
      </Switch>
      <Route
        exact
        path="/"
        children={<UnprotectedRoute children={<HomeLogin />} />}
      />
      <Route
        exact
        path="/forgot-password"
        children={<UnprotectedRoute children={<OTPScreen />} />}
      />
      <Route
        exact
        path="/reset-password"
        children={<UnprotectedRoute children={<ResetPassword />} />}
      />
    </Router>
  );
}
