import { useCallback, useEffect, useRef, useState } from "react";
import { Prompt, useHistory, useParams } from "react-router";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import Header from "components/Header";
import StatusTimeline from "pages/add-edit-episode/StatusTimeline";
import Footer from "./Footer";
import Breadcrumb from "components/Breadcrumb";
import PatientPersonalDetails from "./tabs/PatientPersonalDetails";
import PatientAddress from "./tabs/PatientAddress";
import { keys, tablist, Warnings } from "./constants";
import { PatientFormTabs } from "./enums";
import { useAppDispatch } from "state/store";
import {
  createEpisodeAsync,
  getAllEpisodesAsync,
  getAllFacilitiesAsync,
  getAllPracticesAsync,
  getAllProgramsAsync,
  getAllSurgeonsAsync,
  getEpisodeDetailsByIntakeIdAsync,
  getPreferredPhoneNumberIdAsync,
  getSurgerySiteSideAsync,
  updateEpisodeAsync,
} from "state/features/add-edit-episode/add-edit-episode.action";
import { IAddEditPatientPayload } from "./types";
import { isProcedureDescriptionValid } from "./tabs/SurgeonAndEpisodeDetails/validations";
import {
  genderDropdownItems,
  prefferedPhoneType,
} from "./tabs/PatientPersonalDetails/constants";
import {
  isDobValid,
  isFirstNameValid,
  isLastNameValid,
  isMbiValid,
  isPhoneNumberValid,
} from "./tabs/PatientPersonalDetails/validations";
import {
  isAddressValid,
  isCityValid,
  isZipcodeValid,
} from "./tabs/PatientAddress/validations";
import {
  clearPatientAddressDetailsForm,
  clearPatientPersonalDetailsForm,
  clearSurgeonAndEpisodeDetailsForm,
  getAddEditEpisodeState,
  intializeFormsForAddPatient,
  resetExistingEpisodeDetails,
  resetMetadata,
  setEpisodeDropdownMetaData,
  setIsDirty,
  setIsLoading,
  setPatientAddressDetailsForm,
  setPatientPersonalDetailsForm,
  setSurgeonAndEpisodeDetailsForm,
} from "state/features/add-edit-episode/add-edit-episode.slice";
import {
  IGetPatientEpisodeDetailsResponse,
  InputType,
  IPatientAddressDetailsFormType,
  IPatientPersonalDetailsFormType,
  ISurgeonEpisodeDetailsFormType,
} from "state/types/add-edit-episode-slice.type";
import {
  PATIENT_ADDRESS_DETAILS_FORM,
  PATIENT_PERSONAL_DETAILS_FORM,
  SURGEON_EPISODE_DETAILS_FORM,
} from "state/constants/add-edit-patient";
import {
  episodeCareRadioList,
  episodeSettingRadioList,
  episodeTypeRadioList,
} from "./tabs/SurgeonAndEpisodeDetails/constants";
import { countryList } from "shared/assets/constants/commonConstants";
import EmptyState from "components/empty-state/empty-state.component";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { isEmptyString, validateNumber } from "shared/methods/utilityFunctions";
import { EmptyStateType } from "components/empty-state/empty-state.enum";
import AddEditEpisode from "./add-edit-episode.component";
import "./add-edit-episode.styles.scss";

const AddEditEpisodeContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { intakeId } = useParams<{ intakeId: string }>();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const {
    isPatientSaving,
    surgeonAndEpisodeDetailsForm,
    patientPersonalDetailsForm,
    patientAddressDetailsForm,
    existingEpisodeDetails,
    metadata,
    isDirty,
  } = useSelector(getAddEditEpisodeState);

  const [tablistData, setTabListData] = useState([...tablist]);
  const [currentTabId, setCurrentTabId] = useState<PatientFormTabs>(
    PatientFormTabs.SURGEON_AND_EPISODE_DETAILS
  );

  const [isReadOnlyModeActive, setIsReadOnlyModeActive] =
    useState<boolean>(false);

  const [renderEmptyState, setRenderEmptyState] = useState<boolean>(false);

  useEffect(() => {
    if (!metadata.surgeons.length) {
      dispatch(getAllSurgeonsAsync());
    }
    if (!metadata.preferredPhones.length) {
      dispatch(getPreferredPhoneNumberIdAsync());
    }
    if (!metadata.practices.length) {
      dispatch(getAllPracticesAsync());
    }
    if (!metadata.programs.length) {
      dispatch(getAllProgramsAsync());
    }
    if (!metadata.facilities.length) {
      dispatch(getAllFacilitiesAsync());
    }
  }, []);

  useEffect(() => {
    if (
      !metadata.episodes.length &&
      surgeonAndEpisodeDetailsForm?.program.value &&
      surgeonAndEpisodeDetailsForm.program.value.id &&
      surgeonAndEpisodeDetailsForm.program.value.name
    ) {
      dispatch(
        getAllEpisodesAsync(surgeonAndEpisodeDetailsForm?.program.value.id)
      );
    }
  }, [surgeonAndEpisodeDetailsForm?.program.value]);

  useEffect(() => {
    if (intakeId) {
      appDispatch(getEpisodeDetailsByIntakeIdAsync(intakeId)).then((res) => {
        if (!res.payload) {
          setRenderEmptyState(true);
        }
      });
      return;
    }
    dispatch(intializeFormsForAddPatient());
  }, []);

  useEffect(() => {
    if (existingEpisodeDetails && metadata.episodes.length) {
      const { episodeId } = existingEpisodeDetails;
      const episode = metadata.episodes.find(
        (el: any) => el.key.toString().split(":")[0] === episodeId.toString()
      );
      if (episode) {
        dispatch(getSurgerySiteSideAsync(episode.key.toString().split(":")[1]));
      }
    }
  }, [existingEpisodeDetails, metadata.episodes]);

  useEffect(() => {
    if (isDirty) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isDirty]);

  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message = "Are you sure you want to leave? All changes will be lost.";
    e.returnValue = message;
    return message;
  };

  const prepareSurgeonAndEpisodeDetailsFormForEdit = () => {
    const {
      surgeonId,
      npi,
      practiceId,
      programId,
      episodeId,
      cpt,
      procedureDescription,
      episodeSettingId,
      episodeTypeId,
      episodeCareId,
      surgerySiteSide,
      facilityId,
      ccn,
      admitDate,
      surgeryDate,
      dischargeDate,
    } = existingEpisodeDetails!;

    const disableAllFields =
      !isEmptyString(surgeryDate) && moment() >= moment(surgeryDate);

    const surgeon = metadata.surgeons.find(
      (el: any) => el.key.toString() === surgeonId.toString()
    );

    const practice = metadata.practices.find(
      (el: any) => el.key.toString() === practiceId.toString()
    );
    const program = metadata.programs.find(
      (el: any) => el.key.toString() === programId.toString()
    );
    const episode = metadata.episodes.find(
      (el: any) => el.key.toString().split(":")[0] === episodeId.toString()
    );
    const surgerySite = metadata.surgerySiteSides.find(
      (el: any) => el.key.toString() === surgerySiteSide.toString()
    );
    const facility = metadata.facilities.find(
      (el: any) => el.key.toString() === facilityId.toString()
    );
    const episodeSetting = episodeSettingRadioList.find(
      (el) => el.id.toString() === episodeSettingId.toString()
    );
    const episodeType = episodeTypeRadioList.find(
      (el) => el.id.toString() === episodeTypeId.toString()
    );
    const episodeCare = episodeCareRadioList.find(
      (el) => el.id.toString() === episodeCareId.toString()
    );

    const editFormDetails = { ...SURGEON_EPISODE_DETAILS_FORM };
    editFormDetails.surgeonName = {
      ...editFormDetails.surgeonName,
      value: surgeon.name,
      isDisabled: true,
    };
    editFormDetails.surgeonId = {
      ...editFormDetails.surgeonId,
      value: surgeonId.toString(),
      isDisabled: true,
    };

    editFormDetails.npi = {
      ...editFormDetails.npi,
      value: npi,
      isDisabled: true,
    };

    editFormDetails.surgeonPractice = {
      ...editFormDetails.surgeonPractice,
      value: practice
        ? { name: practice.name, id: practiceId.toString() }
        : { name: "", id: "" },
      isDisabled: true,
    };

    editFormDetails.program = {
      ...editFormDetails.program,
      value: program
        ? { name: program.name, id: programId.toString() }
        : { name: "", id: "" },
      isDisabled: true,
    };

    editFormDetails.episode = {
      ...editFormDetails.episode,
      value: episode
        ? { name: episode.name, id: episodeId.toString() }
        : { name: "", id: "" },
      isDisabled: disableAllFields,
    };

    editFormDetails.cpt = {
      ...editFormDetails.cpt,
      value: cpt,
      isDisabled: disableAllFields,
    };

    editFormDetails.procedureDescription = {
      ...editFormDetails.procedureDescription,
      value: procedureDescription,
      isDisabled: disableAllFields,
    };

    editFormDetails.episodeSettings = {
      ...editFormDetails.episodeSettings,
      value: { name: episodeSetting?.text, id: episodeSettingId.toString() },
      isDisabled: disableAllFields,
    };

    editFormDetails.episodeType = {
      ...editFormDetails.episodeType,
      value: { name: episodeType?.text, id: episodeTypeId.toString() },
      isDisabled: disableAllFields,
    };

    editFormDetails.episodeCare = {
      ...editFormDetails.episodeCare,
      value: { name: episodeCare?.text, id: episodeCareId.toString() },
      isDisabled: disableAllFields,
    };

    editFormDetails.surgerySiteSide = {
      ...editFormDetails.surgerySiteSide,
      value: surgerySite
        ? { name: surgerySite?.name, id: surgerySite?.key.toString() }
        : { name: "", id: "" },
      isDisabled: disableAllFields,
    };

    editFormDetails.acuteCareFacility = {
      ...editFormDetails.acuteCareFacility,
      value: facility
        ? { name: facility.name, id: facilityId.toString() }
        : { name: "", id: "" },
      isDisabled: disableAllFields,
    };

    editFormDetails.ccn = {
      ...editFormDetails.ccn,
      value: ccn,
      isDisabled: true,
    };

    editFormDetails.admitDate = {
      ...editFormDetails.admitDate,
      value: admitDate,
      isDisabled: disableAllFields,
    };

    editFormDetails.surgeryDate = {
      ...editFormDetails.surgeryDate,
      value: surgeryDate,
      isDisabled: disableAllFields,
    };

    editFormDetails.dischargeDate = {
      ...editFormDetails.dischargeDate,
      value: dischargeDate,
      isDisabled: disableAllFields,
    };

    dispatch(
      setSurgeonAndEpisodeDetailsForm({
        formValue: editFormDetails,
        isFormDirty: false,
      })
    );
  };

  const preparePatientPersonalDetailsFormForEdit = () => {
    const {
      firstName,
      middleName,
      lastName,
      suffix,
      email,
      genderId,
      dob,
      emegencyContactName,
      emegencyContactNumber,
      home,
      cell,
      preferredPhone,
      mbi,
      mbiEffectiveDate,
      mrn,
      surgeryDate,
    } = existingEpisodeDetails!;

    const disableAllFields =
      !isEmptyString(surgeryDate) && moment() >= moment(surgeryDate);

    const gender = genderDropdownItems.find(
      (el) => el.id.toString() === genderId.toString()
    );
    const preferedPhone =
      prefferedPhoneType.CELL.id === preferredPhone
        ? {
            id: prefferedPhoneType.CELL.id,
            name: prefferedPhoneType.CELL.text,
          }
        : {
            id: prefferedPhoneType.HOME.id,
            name: prefferedPhoneType.HOME.text,
          };

    const editFormDetails = { ...PATIENT_PERSONAL_DETAILS_FORM };
    editFormDetails.firstName = {
      ...editFormDetails.firstName,
      value: firstName,
      isDisabled: disableAllFields,
    };

    editFormDetails.middleName = {
      ...editFormDetails.middleName,
      value: middleName,
      isDisabled: disableAllFields,
    };

    editFormDetails.lastName = {
      ...editFormDetails.lastName,
      value: lastName,
      isDisabled: disableAllFields,
    };

    editFormDetails.suffix = {
      ...editFormDetails.suffix,
      value: suffix,
      isDisabled: disableAllFields,
    };

    editFormDetails.email = {
      ...editFormDetails.email,
      value: email,
      isDisabled: true,
    };

    editFormDetails.gender = {
      ...editFormDetails.gender,
      value: { id: genderId.toString(), name: gender?.name.toString() },
      isDisabled: disableAllFields,
    };

    editFormDetails.dob = {
      ...editFormDetails.dob,
      value: dob,
      isDisabled: disableAllFields,
    };

    editFormDetails.emegencyContactName = {
      ...editFormDetails.emegencyContactName,
      value: emegencyContactName,
      isDisabled: disableAllFields,
    };

    editFormDetails.emegencyContactNumber = {
      ...editFormDetails.emegencyContactNumber,
      value: {
        ...editFormDetails.emegencyContactNumber.value,
        number: emegencyContactNumber,
      },
      isDisabled: disableAllFields,
    };

    editFormDetails.cell = {
      ...editFormDetails.cell,
      value: { ...editFormDetails.cell.value, number: cell },
      isDisabled: disableAllFields,
    };

    editFormDetails.home = {
      ...editFormDetails.home,
      value: { ...editFormDetails.home.value, number: home },
      isDisabled: disableAllFields,
    };

    editFormDetails.preferredPhone = {
      ...editFormDetails.preferredPhone,
      value: preferedPhone,
      isDisabled: disableAllFields,
    };

    editFormDetails.mbi = {
      ...editFormDetails.mbi,
      value: mbi,
      isDisabled: disableAllFields,
    };

    editFormDetails.mbiEffectiveDate = {
      ...editFormDetails.mbiEffectiveDate,
      value: mbiEffectiveDate,
      isDisabled: disableAllFields,
    };

    editFormDetails.mrn = {
      ...editFormDetails.mrn,
      value: mrn,
      isDisabled: disableAllFields,
    };

    dispatch(
      setPatientPersonalDetailsForm({
        formValue: editFormDetails,
        isFormDirty: false,
      })
    );
  };

  const preparePatientAddressDetailsFormForEdit = () => {
    const { city, zipcode, address, state, surgeryDate } =
      existingEpisodeDetails!;

    const disableAllFields =
      !isEmptyString(surgeryDate) && moment() >= moment(surgeryDate);

    const stateValue = countryList.find((el) => el.name === state);

    const editFormDetails = { ...PATIENT_ADDRESS_DETAILS_FORM };
    editFormDetails.address = {
      ...editFormDetails.address,
      value: address,
      isDisabled: disableAllFields,
    };

    editFormDetails.zipcode = {
      ...editFormDetails.zipcode,
      value: zipcode,
      isDisabled: disableAllFields,
    };

    editFormDetails.city = {
      ...editFormDetails.city,
      value: city,
      isDisabled: disableAllFields,
    };

    editFormDetails.state = {
      ...editFormDetails.state,
      value: stateValue?.name,
      isDisabled: disableAllFields,
    };
    dispatch(
      setPatientAddressDetailsForm({
        formValue: editFormDetails,
        isFormDirty: false,
      })
    );
  };

  useEffect(() => {
    if (intakeId && existingEpisodeDetails) {
      const { surgeryDate } = existingEpisodeDetails;
      const disableAllFields =
        !isEmptyString(surgeryDate) && moment() >= moment(surgeryDate);
      setIsReadOnlyModeActive(disableAllFields);
    }
  }, [existingEpisodeDetails]);

  useEffect(() => {
    if (
      metadata.surgeons.length &&
      metadata.practices.length &&
      metadata.programs.length &&
      metadata.episodes.length &&
      metadata.surgerySiteSides.length &&
      metadata.facilities.length &&
      surgeonAndEpisodeDetailsForm == null
    ) {
      if (intakeId && existingEpisodeDetails) {
        prepareSurgeonAndEpisodeDetailsFormForEdit();
        preparePatientPersonalDetailsFormForEdit();
        preparePatientAddressDetailsFormForEdit();
      }
      dispatch(setIsLoading(false));
    }
  }, [
    existingEpisodeDetails,
    metadata.surgeons,
    metadata.practices,
    metadata.programs,
    metadata.episodes,
    metadata.surgerySiteSides,
    metadata.facilities,
  ]);

  useEffect(() => {
    return () => {
      localStorage.removeItem(keys.SURGEON_AND_EPISODE_DETAILS);
      localStorage.removeItem(keys.PATIENT_PERSONAL_DETAILS);
      dispatch(resetMetadata());
      dispatch(resetExistingEpisodeDetails());
      dispatch(setSurgeonAndEpisodeDetailsForm(null));
      dispatch(setPatientPersonalDetailsForm(null));
      dispatch(setPatientAddressDetailsForm(null));
    };
  }, []);

  const scrollToError = useCallback(() => {
    let inputs: Array<InputType> = [];
    if (currentTabId === PatientFormTabs.SURGEON_AND_EPISODE_DETAILS) {
      inputs = Object.values(surgeonAndEpisodeDetailsForm!);
    }
    if (currentTabId === PatientFormTabs.PATIENT_PERSONAL_DETAILS) {
      inputs = Object.values(patientPersonalDetailsForm!);
    }
    if (currentTabId === PatientFormTabs.PATIENT_ADDRESS) {
      inputs = Object.values(patientAddressDetailsForm!);
    }

    let i = 0;
    while (i < inputs.length) {
      if (!inputs[i].isValid && document.getElementById(inputs[i].id)) {
        document.getElementById(inputs[i].id)!.scrollIntoView(true);
        break;
      }
      i++;
    }
  }, [
    surgeonAndEpisodeDetailsForm,
    patientPersonalDetailsForm,
    patientAddressDetailsForm,
  ]);

  useEffect(() => {
    if (
      surgeonAndEpisodeDetailsForm &&
      patientPersonalDetailsForm &&
      patientAddressDetailsForm
    ) {
      scrollToError();
    }
  }, [scrollToError]);

  const checkSurgeonEpiosdeDetailsFormValidation = () => {
    const {
      surgeonName,
      surgeonPractice,
      program,
      episode,
      procedureDescription,
      episodeSettings,
      surgerySiteSide,
      episodeCare,
      episodeType,
      acuteCareFacility,
      admitDate,
      surgeryDate,
    } = surgeonAndEpisodeDetailsForm!;

    const updatedForm = {
      ...surgeonAndEpisodeDetailsForm,
      surgeonName: { ...surgeonName, isValid: !!surgeonName.value },
      surgeonPractice: {
        ...surgeonPractice,
        isValid: !!surgeonPractice.value.name,
      },
      program: { ...program, isValid: !!program.value.name },
      episode: { ...episode, isValid: !!episode.value.name },
      procedureDescription: {
        ...procedureDescription,
        isValid: procedureDescription.isDisabled
          ? true
          : isProcedureDescriptionValid(procedureDescription.value),
      },
      episodeSettings: {
        ...episodeSettings,
        isValid: !!episodeSettings.value.name,
      },
      episodeType: {
        ...episodeType,
        isValid: !!episodeType.value.name,
      },
      episodeCare: {
        ...episodeCare,
        isValid: !!episodeCare.value.name,
      },
      surgerySiteSide: {
        ...surgerySiteSide,
        isValid: !!surgerySiteSide.value.name,
      },
      acuteCareFacility: {
        ...acuteCareFacility,
        isValid: !!acuteCareFacility.value.name,
      },
      admitDate: { ...admitDate, isValid: !!admitDate.value },
      surgeryDate: { ...surgeryDate, isValid: !!surgeryDate.value },
    } as ISurgeonEpisodeDetailsFormType;
    dispatch(
      setSurgeonAndEpisodeDetailsForm({
        formValue: updatedForm,
        isFormDirty: isDirty,
      })
    );

    let isValid = true;
    Object.values(updatedForm).forEach((input: InputType) => {
      if (!input.isDisabled && (input.isRequired || input.value)) {
        isValid = isValid && input.isValid;
      }
    });
    return isValid;
  };

  const checkPatientPersonalDetailsFormValidation = () => {
    const { firstName, lastName, dob, cell, preferredPhone, home, mbi } =
      patientPersonalDetailsForm!;

    let updatedForm = {
      ...patientPersonalDetailsForm,
      firstName: { ...firstName, isValid: isFirstNameValid(firstName.value) },
      lastName: { ...lastName, isValid: isLastNameValid(lastName.value) },
      dob: { ...dob, isValid: isDobValid(dob.value) },
      mbi: { ...mbi, isValid: isMbiValid(mbi.value) },
    } as IPatientPersonalDetailsFormType;

    if (preferredPhone.value.name === prefferedPhoneType.HOME.text) {
      updatedForm = {
        ...updatedForm,
        home: { ...home, isValid: isPhoneNumberValid(home.value.number) },
      };
    }
    if (preferredPhone.value.name === prefferedPhoneType.CELL.text) {
      updatedForm = {
        ...updatedForm,
        cell: { ...cell, isValid: isPhoneNumberValid(cell.value.number) },
      };
    }
    dispatch(
      setPatientPersonalDetailsForm({
        formValue: updatedForm,
        isFormDirty: isDirty,
      })
    );
    let isValid = true;
    Object.values(updatedForm).forEach((input: InputType) => {
      if (!input.isDisabled && (input.isRequired || input.value)) {
        isValid = isValid && input.isValid;
      }
    });
    return isValid;
  };

  const checkPatientAddressDetailsFormValidation = () => {
    const { address, zipcode, city, state } = patientAddressDetailsForm!;
    const updatedForm = {
      ...patientAddressDetailsForm,
      address: {
        ...address,
        isValid: isAddressValid(address.value),
      },
      zipcode: {
        ...zipcode,
        isValid: isZipcodeValid(zipcode.value),
      },
      city: {
        ...city,
        isValid: isCityValid(city.value),
      },
      state: { ...state, isValid: !!state.value },
    } as IPatientAddressDetailsFormType;
    dispatch(
      setPatientAddressDetailsForm({
        formValue: updatedForm,
        isFormDirty: isDirty,
      })
    );
    let isValid = true;
    Object.values(updatedForm).forEach((input: InputType) => {
      if (!input.isDisabled && (input.isRequired || input.value)) {
        isValid = isValid && input.isValid;
      }
    });
    return isValid;
  };

  const isCurrentTabValid = () => {
    if (currentTabId === PatientFormTabs.SURGEON_AND_EPISODE_DETAILS) {
      return checkSurgeonEpiosdeDetailsFormValidation();
    }
    if (currentTabId === PatientFormTabs.PATIENT_PERSONAL_DETAILS) {
      return checkPatientPersonalDetailsFormValidation();
    }
    if (currentTabId === PatientFormTabs.PATIENT_ADDRESS) {
      return checkPatientAddressDetailsFormValidation();
    }
    return false;
  };

  const onTabChangeFromStatusTimeline = (tabId: PatientFormTabs) => {
    if (intakeId && existingEpisodeDetails) {
      if (tabId < currentTabId) {
        setCurrentTabId(tabId);
      } else if (isCurrentTabValid()) {
        setFormDetailsInLocalStorage();
        setCurrentTabId(tabId);
      }

      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: 0,
        });
      }
    }
  };

  const handleBackClick = () => {
    const copyOfTablistData = [...tablistData];
    const currentTabIdx = copyOfTablistData.findIndex(
      (tab) => tab.id === currentTabId
    );
    if (currentTabIdx === 0) {
      return;
    }
    setCurrentTabId(copyOfTablistData[currentTabIdx - 1].id);
    setTabListData(copyOfTablistData);
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
      });
    }
  };

  const setFormDetailsInLocalStorage = () => {
    if (currentTabId === PatientFormTabs.SURGEON_AND_EPISODE_DETAILS) {
      localStorage.setItem(
        keys.SURGEON_AND_EPISODE_DETAILS,
        JSON.stringify(surgeonAndEpisodeDetailsForm)
      );
    }
    if (currentTabId === PatientFormTabs.PATIENT_PERSONAL_DETAILS) {
      localStorage.setItem(
        keys.PATIENT_PERSONAL_DETAILS,
        JSON.stringify(patientPersonalDetailsForm)
      );
    }
  };

  const handleNextClick = () => {
    let isCurentTabDetailsValid = false;
    if (currentTabId === PatientFormTabs.SURGEON_AND_EPISODE_DETAILS) {
      isCurentTabDetailsValid = checkSurgeonEpiosdeDetailsFormValidation();
    }
    if (currentTabId === PatientFormTabs.PATIENT_PERSONAL_DETAILS) {
      isCurentTabDetailsValid = checkPatientPersonalDetailsFormValidation();
    }

    if (isCurentTabDetailsValid) {
      const copyOfTablistData = [...tablistData];
      const currentTabIdx = copyOfTablistData.findIndex(
        (tab) => tab.id === currentTabId
      );
      setCurrentTabId(copyOfTablistData[currentTabIdx + 1].id);
      setFormDetailsInLocalStorage();
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: 0,
        });
      }
    }
  };

  const getCreatePatientPayload = () => {
    const {
      surgeonId,
      surgeonPractice,
      program,
      episode,
      procedureDescription,
      cpt,
      surgerySiteSide,
      acuteCareFacility,
      episodeType,
      episodeCare,
      episodeSettings,
      admitDate,
      surgeryDate,
      dischargeDate,
      npi,
      ccn,
    } = surgeonAndEpisodeDetailsForm!;
    const {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      suffix,
      mbi,
      mbiEffectiveDate,
      mrn,
      preferredPhone,
      home,
      cell,
      emegencyContactName,
      emegencyContactNumber,
      email,
    } = patientPersonalDetailsForm!;
    const { address, zipcode, city, state } = patientAddressDetailsForm!;
    const payload: IAddEditPatientPayload = {
      Warnings,
      surgeonID: parseInt(surgeonId.value, 10),
      practiceID: parseInt(surgeonPractice.value.id, 10),
      programID: parseInt(program.value.id, 10),
      episodeID: episode.value.id,
      procedureDescription: procedureDescription.value,
      cpt: cpt.value,
      surgerySiteSide: parseInt(surgerySiteSide.value.id, 10),
      facilityID: parseInt(acuteCareFacility.value.id, 10),
      episodeTypeID: parseInt(episodeSettings.value.id, 10),
      medSurg: parseInt(episodeCare.value.id, 10),
      plannedUnplanned: parseInt(episodeType.value.id, 10),
      admitDate: moment(admitDate.value).format("MM/DD/YYYY"),
      surgeryDate: moment(surgeryDate.value).format("MM/DD/YYYY"),
      dischargeDate: dischargeDate.value
        ? moment(dischargeDate.value).format("MM/DD/YYYY")
        : "",
      npi: npi.value,
      ccn: ccn.value,
      mbi: mbi.value,
      mrn: mrn.value,
      mbiEffectiveDate: !!mbiEffectiveDate.value
        ? moment(mbiEffectiveDate.value).format("MM/DD/YYYY")
        : "",
      firstName: firstName.value,
      middleName: middleName.value,
      lastName: lastName.value,
      genderId: gender.value.id,
      dob: moment(dob.value).format("MM/DD/YYYY"),
      address1: address.value,
      city: city.value,
      state: state.value,
      zip: zipcode.value,
      phoneHome: home.value.number ? home.value.code + home.value.number : "",
      phoneCell: cell.value.number ? cell.value.code + cell.value.number : "",
      suffix: suffix.value,
      emergencyContactName: emegencyContactName.value,
      phoneEmergency: emegencyContactNumber.value.number
        ? emegencyContactNumber.value.code + emegencyContactNumber.value.number
        : "",
      email: email.value,
      preferredPhone: preferredPhone.value.id,
    };

    return payload;
  };

  const handleSaveClick = async () => {
    const isCurentTabDetailsValid = checkPatientAddressDetailsFormValidation();
    if (
      currentTabId === PatientFormTabs.PATIENT_ADDRESS &&
      isCurentTabDetailsValid
    ) {
      setOpenConfirmationModal(true);
    }
  };

  const afterConfirmationModalClose = () => {
    const isCurentTabDetailsValid = checkPatientAddressDetailsFormValidation();
    if (
      currentTabId === PatientFormTabs.PATIENT_ADDRESS &&
      isCurentTabDetailsValid
    ) {
      if (intakeId && existingEpisodeDetails) {
        updatePatient();
      } else {
        savePatient();
      }
    }
  };

  const savePatient = async () => {
    const payload = getCreatePatientPayload();
    dispatch(setIsDirty(false));
    try {
      const result = await appDispatch(createEpisodeAsync(payload)).unwrap();
      if (result.status === 200) {
        setTimeout(() => {
          localStorage.removeItem(keys.SURGEON_AND_EPISODE_DETAILS);
          localStorage.removeItem(keys.PATIENT_PERSONAL_DETAILS);
        }, 1);
        history.replace("/patient-episodes");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePatient = async () => {
    const payload = getCreatePatientPayload();
    dispatch(setIsDirty(false));
    const { intakeId, intakeStatusId, primaryCareManagerId, id } =
      existingEpisodeDetails!;
    payload.patientId = parseInt(id, 10);
    payload.intakeStatusID = intakeStatusId;
    payload.intakeId = intakeId;
    payload.primaryCareManagerId = primaryCareManagerId;
    try {
      const result = await appDispatch(updateEpisodeAsync(payload)).unwrap();
      if (result.status === 200) {
        setTimeout(() => {
          localStorage.removeItem(keys.SURGEON_AND_EPISODE_DETAILS);
          localStorage.removeItem(keys.PATIENT_PERSONAL_DETAILS);
        }, 1);
        history.replace("/patient-episodes");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearClick = () => {
    if (currentTabId === PatientFormTabs.SURGEON_AND_EPISODE_DETAILS) {
      dispatch(clearSurgeonAndEpisodeDetailsForm());
    }
    if (currentTabId === PatientFormTabs.PATIENT_PERSONAL_DETAILS) {
      dispatch(clearPatientPersonalDetailsForm());
    }
    if (currentTabId === PatientFormTabs.PATIENT_ADDRESS) {
      dispatch(clearPatientAddressDetailsForm());
    }
  };

  const updateFormData = (val: any) =>
    dispatch(
      setSurgeonAndEpisodeDetailsForm({
        formValue: val,
        isFormDirty: true,
      })
    );

  const getCurrentActiveTab = () => {
    if (
      currentTabId === PatientFormTabs.SURGEON_AND_EPISODE_DETAILS &&
      surgeonAndEpisodeDetailsForm
    ) {
      return (
        <AddEditEpisode
          form={surgeonAndEpisodeDetailsForm}
          setForm={updateFormData}
        />
      );
    }
    if (
      currentTabId === PatientFormTabs.PATIENT_PERSONAL_DETAILS &&
      patientPersonalDetailsForm
    ) {
      return (
        <PatientPersonalDetails
          form={patientPersonalDetailsForm}
          setForm={(val) =>
            dispatch(
              setPatientPersonalDetailsForm({
                formValue: val,
                isFormDirty: true,
              })
            )
          }
        />
      );
    }
    if (
      currentTabId === PatientFormTabs.PATIENT_ADDRESS &&
      patientAddressDetailsForm
    ) {
      return (
        <PatientAddress
          form={patientAddressDetailsForm}
          setForm={(val) =>
            dispatch(
              setPatientAddressDetailsForm({
                formValue: val,
                isFormDirty: true,
              })
            )
          }
        />
      );
    }
    return <EmptyState type={EmptyStateType.LOADING} />;
  };

  const getClassName = () => {
    if (currentTabId === PatientFormTabs.SURGEON_AND_EPISODE_DETAILS) {
      return "input-fields-section";
    }
    if (currentTabId === PatientFormTabs.PATIENT_PERSONAL_DETAILS) {
      return "patient-input-fields-section";
    }
    return "input-fields-section";
  };

  const getPatientName = (
    patientDetails: IGetPatientEpisodeDetailsResponse
  ) => {
    const { firstName, middleName, lastName } = patientDetails;
    let patientName = "-";
    if (firstName) {
      patientName = `${firstName}`;
    }
    if (middleName) {
      patientName += ` ${middleName}`;
    }
    if (lastName) {
      patientName += ` ${lastName}`;
    }
    return patientName;
  };

  const getPathForBreadcumb = () => {
    if (intakeId && !existingEpisodeDetails) {
      return "";
    }
    return intakeId && existingEpisodeDetails
      ? `/patient-episodes/${getPatientName(existingEpisodeDetails)}`
      : "/patient-episodes/add";
  };

  const getHeaderTitle = () => (intakeId ? "Edit Episode" : "Add Episode");

  useEffect(() => {
    if (existingEpisodeDetails?.programId) {
      dispatch(getAllEpisodesAsync(existingEpisodeDetails.programId));
      preparePatientPersonalDetailsFormForEdit();
    }
  }, [existingEpisodeDetails?.programId]);

  return (
    <>
      <Prompt
        when={isDirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {openConfirmationModal ? (
        <ConfirmationModal
          isOpen={openConfirmationModal}
          header={intakeId ? "Edit Episode" : "Add Episode"}
          content={"Are you sure you want to submit the episode?"}
          handleCancelClick={() => setOpenConfirmationModal(false)}
          handleConfirmClick={() => {
            setOpenConfirmationModal(false);
            afterConfirmationModalClose();
          }}
        />
      ) : null}
      {renderEmptyState ? (
        <EmptyState
          type={EmptyStateType.NO_PATIENT_EPISODE}
          onClick={() => history.replace("/patient-episodes")}
        />
      ) : (
        <div className="my-patient-screen">
          <div id="add-patient-container">
            <Breadcrumb pathname={getPathForBreadcumb()} isPatientPage={true} />
            <Header className="profile">
              <div className="header-title">{getHeaderTitle()}</div>
            </Header>
            <div className="tab-section">
              <StatusTimeline
                intakeId={intakeId}
                tabList={tablistData}
                currentTabId={currentTabId}
                onTabChange={(tabId) => onTabChangeFromStatusTimeline(tabId)}
              />
            </div>
            <div ref={scrollRef} className={getClassName()}>
              {getCurrentActiveTab()}
            </div>
            {surgeonAndEpisodeDetailsForm ? (
              <div className="footer-button-container">
                <Footer
                  activeTabId={currentTabId}
                  isSaveCtaDisabled={
                    isPatientSaving || (!!intakeId && !isDirty)
                  }
                  isSaveCtaLoading={isPatientSaving}
                  handleBackClick={handleBackClick}
                  handleNextClick={handleNextClick}
                  handleSaveClick={() => handleSaveClick()}
                  handleClearClick={handleClearClick}
                  hideSaveCta={isReadOnlyModeActive}
                  hideClearCta={isReadOnlyModeActive}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default AddEditEpisodeContainer;
