import SessionTimeOutModal from "components/Modal/SessionTimeoutModal";
import AppRoutes from "routes";
import { ToastContainer } from "react-toastify";
import { useAppDispatch } from "./state/store";
import { useSelector } from "react-redux";
import { getCommon, resetCommon } from "./state/features/common/common.slice";
import { getConfigurationSettings } from "./state/features/common/common.action";
import EmptyState from "./components/empty-state/empty-state.component";
import React, { useEffect } from "react";
import { EmptyStateType } from "components/empty-state/empty-state.enum";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { resetTocs } from "state/features/toc/toc.slice";
import { resetPatientEpisodes } from "state/features/patient-episodes/patient-episodes.slice";
import { resetMessages } from "state/features/messages/messages.slice";
import { clearUser } from "state/features/authentication/authentication.slice";
import {
  resetExistingEpisodeDetails,
  resetMetadata,
} from "state/features/add-edit-episode/add-edit-episode.slice";
import { CUSTOM_EVENT_NAMES } from "shared/constant/constants";
import { batch, useDispatch } from "react-redux";

const App = () => {
  const dispatch = useDispatch();
  const { isAppInitialized } = useSelector(getCommon);
  useAxiosAuthenticated();

  useEffect(() => {
    document.addEventListener(CUSTOM_EVENT_NAMES.CLEAR_REDUX_DATA, () => {
      batch(() => {
        dispatch(resetMetadata());
        dispatch(resetExistingEpisodeDetails());
        dispatch(clearUser());
        dispatch(resetCommon());
        dispatch(resetMessages());
        dispatch(resetPatientEpisodes());
        dispatch(resetTocs());
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAppInitialized) {
    dispatch(getConfigurationSettings());
  }

  if (!isAppInitialized) {
    return (
      <div className="loading-container">
        <EmptyState type={EmptyStateType.LOADING} />
      </div>
    );
  } else {
    return (
      <>
        <div className="app">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            limit={2}
            pauseOnHover
          />

          <SessionTimeOutModal />
          <AppRoutes />
        </div>
      </>
    );
  }
};

export default App;
