import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getConfig } from "config/config";
import { axiosPrivate } from "shared/api/axios-wrapper.service";
import { IGetPatientEpisodeDetailsResponse } from "state/types/add-edit-episode-slice.type";

export const createEpisodeAsync = createAsyncThunk(
  "addEditPatients/createEpisodeAsync",
  async (requestPayload: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${getConfig().efusionBase}/api/v2/ExtIntake/IntakeWithPatient`,
        requestPayload
      );
      return response;
    } catch (error: any) {
      const errorResponse = error.response.data;
      return rejectWithValue(errorResponse.ErrorMessage);
    }
  }
);

export const updateEpisodeAsync = createAsyncThunk(
  "addEditPatients/updateEpisodeAsync",
  async (requestPayload: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(
        `${getConfig().efusionBase}/api/v2/ExtIntake/IntakeWithPatient`,
        requestPayload
      );
      return response;
    } catch (error: any) {
      const errorResponse = error.response.data;
      return rejectWithValue(errorResponse.ErrorMessage);
    }
  }
);

export const getPreferredPhoneNumberIdAsync = createAsyncThunk(
  "addEditPatients/getPreferredPhoneNumberIdAsync",
  async () => {
    const response = await axiosPrivate.get(
      `${getConfig().efusionBase}/api/v2/ExtGenericListItem`,
      { params: { ItemGroup: "PreferredPhone" } }
    );
    return response.data;
  }
);

export const getSurgerySiteSideAsync = createAsyncThunk(
  "addEditPatients/getSurgerySiteSideAsync",
  async (requestPayload: string) => {
    const response = await axiosPrivate.get(
      `${getConfig().efusionBase}/api/v2/ExtSurgeryCategory`,
      { params: { SurgeryCategoryID: requestPayload } }
    );
    return response.data;
  }
);

export const getAllEpisodesAsync = createAsyncThunk(
  "addEditPatients/getAllEpisodesAsync",
  async (requestPayload: number) => {
    const response = await axiosPrivate.get(
      `${getConfig().efusionBase}/api/v2/ExtEpisode`,
      {
        params: {
          isActive: true,
          ProgramID: requestPayload,
        },
      }
    );
    return response.data;
  }
);

export const getAllSurgeonsAsync = createAsyncThunk(
  "addEditPatients/getAllSurgeonsAsync",
  async () => {
    const response = await axiosPrivate.get(
      `${
        getConfig().efusionBase
      }/api/v2/ExtProvider/getAll/false/false/true/false/true`,
      { params: {} }
    );
    return response.data;
  }
);

export const getAllFacilitiesAsync = createAsyncThunk(
  "addEditPatients/getAllFacilitiesAsync",
  async () => {
    const response = await axiosPrivate.get(
      `${getConfig().efusionBase}/api/v2/ExtProvider/acuteCareFacility`,
      { params: {} }
    );
    return response.data;
  }
);

export const getAllPracticesAsync = createAsyncThunk(
  "addEditPatients/getAllPracticesAsync",
  async () => {
    const response = await axiosPrivate.get(
      `${getConfig().efusionBase}/api/v2/ExtPractice`,
      { params: {} }
    );
    return response.data;
  }
);

export const getAllProgramsAsync = createAsyncThunk(
  "addEditPatients/getAllProgramsAsync",
  async () => {
    const response = await axiosPrivate.get(
      `${getConfig().efusionBase}/api/v2/ExtProgram`,
      { params: {} }
    );
    return response.data;
  }
);

export const getEpisodeDetailsByIntakeIdAsync = createAsyncThunk<
  IGetPatientEpisodeDetailsResponse,
  string
>(
  "addEditPatients/getEpisodeDetailsByIntakeIdAsync",
  async (intakeId: string) => {
    try {
      const response = await axiosPrivate.get(
        `${
          getConfig().efusionBase
        }/api/v2/ExtIntake/getIntakeWithPatientDetails/${intakeId}`
      );
      if (response.status === 204) {
        return null;
      }
      if (response.status === 200) {
        const {
          id,
          surgeonId,
          npi,
          practiceId,
          programId,
          episodeId,
          cpt,
          procedureDescription,
          episodeSettingId,
          episodeTypeId,
          episodeCareId,
          surgerySiteSide,
          facilityId,
          ccn,
          admitDate,
          surgeryDate,
          intakeStatusId,
          intakeId,
          firstName,
          middleName,
          lastName,
          suffix,
          email,
          genderId,
          dob,
          emergencyContactName,
          emergencyContactNumber,
          cellPhone,
          homePhone,
          preferredPhone,
          mbi,
          mbiEffectiveDate,
          mrn,
          address1,
          zipcode,
          city,
          state,
          primaryCareManagerId,
          dischargeDate,
        } = response.data;
        return {
          id,
          surgeonId,
          npi: npi ?? "",
          practiceId,
          programId,
          episodeId,
          cpt: cpt ?? "",
          procedureDescription: procedureDescription ?? "",
          episodeSettingId,
          episodeTypeId,
          episodeCareId,
          surgerySiteSide,
          facilityId,
          ccn: ccn ?? "",
          admitDate,
          surgeryDate,
          intakeStatusId,
          firstName,
          middleName: middleName ?? "",
          lastName,
          suffix: suffix ?? "",
          email: email ?? "",
          genderId,
          dob,
          emegencyContactName: emergencyContactName ?? "",
          emegencyContactNumber: emergencyContactNumber?.toString().slice(2),
          cell: cellPhone?.toString().slice(2),
          home: homePhone?.toString().slice(2),
          preferredPhone,
          mbi: mbi ?? "",
          mbiEffectiveDate: mbiEffectiveDate ?? "",
          mrn: mrn ?? "",
          address: address1 ?? "",
          zipcode: zipcode ?? "",
          city: city ?? "",
          state: state ?? "",
          intakeId,
          primaryCareManagerId,
          dischargeDate,
        } as IGetPatientEpisodeDetailsResponse;
      }
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

export const getAcuteFacilityCareByIdAsync = createAsyncThunk(
  "addEditPatients/getAcuteFacilityCareByIdAsync",
  async (requestPayload: { facilityId: string }) => {
    const response = await axiosPrivate.get(
      `${getConfig().efusionBase}/api/v2/ExtProvider/${
        requestPayload.facilityId
      }`
    );
    return response.data;
  }
);
