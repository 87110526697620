import React, { useEffect, useState } from "react";
import Icon from "components/Icon";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "shared/assets/images/logo.png";
import "./styles.scss";
import Avatar from "components/Avatar";
import { useSelector } from "react-redux";
import { getCommon } from "state/features/common/common.slice";
import { getAuth } from "state/features/authentication/authentication.slice";
import { CustomToolTip } from "components/tool-tip/tool-tip.component";
import { NavbarIconsType } from "routes/nav.type";

type SideBarProps = {
  routes: NavbarIconsType[];
};

const SideBar = ({ routes }: SideBarProps) => {
  const location = useLocation();
  const commonState = useSelector(getCommon);
  const authState = useSelector(getAuth);

  return (
    <div className="side-bar">
      <div className="side-bar-top">
        <div className="side-bar-header">
          {/* As logo is not properly loading with icomoon we will be using an image for that*/}
          <img src={Logo} alt="logo" className="logo" />
        </div>
        <ul className="side-bar-body">
          {routes.map((route) => {
            const isActive = location.pathname === route.path;
            return (
              <li
                key={route.path}
                className="nav-item"
                data-tip
                data-for={route.name}
              >
                <NavLink
                  exact
                  to={route.path}
                  className={isActive ? "is-active-nav-item" : ""}
                >
                  <Icon
                    icon={isActive ? route.iconSelected : route.icon}
                    size={isActive ? route.selectedSize : route.size}
                    className={route.className}
                  />
                  <CustomToolTip text={route.name} place="left" />
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className={`side-bar-bottom ${
          commonState.isFooterVisible ? "with-bottom-margin" : ""
        }`}
      >
        {authState.auth.isAuthenticated && (
          <Avatar
            personName={
              authState.user.firstName + " " + authState.user.lastName
            }
          />
        )}
      </div>
    </div>
  );
};

export default SideBar;
