import { createSlice } from "@reduxjs/toolkit";
import { PAGE_LIMIT } from "shared/assets/constants/commonConstants";
import { ICurrentChat, IMessagesState } from "state/types/messages-slice.type";
import {
  getPatientDetailsForConversationAsync,
  requestcreateConversationAsync,
  requestFetchAllContactsAsync,
  requestGetConversationIfPresentAsync,
  requestGetMessageLogsAsync,
} from "./messages.action";
import moment from "moment";
import { toast } from "react-toastify";

const initialState: IMessagesState = {
  isLoading: false,
  isSilentLoading: false,
  isRefreshLoading: false,
  isLoadingContacts: false,
  isLoadingChat: false,
  isSearching: false,
  isError: false,
  hasMoreMessages: false,
  messages: [],
  allContacts: [],
  currentChat: {
    name: "Messages",
    conversationId: -1,
    twilioConversationId: "",
    participants: [],
    patientDOB: null,
    subject: "",
    chatTitle: "",
  },
  currentChatMessages: [],
  patientInConversation: null,
  openMessagingParticipantsPopup: false,
  isLoadingPatientDetailsInConversation: false,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setIsLoading: (state: any, action) => {
      state.isLoading = action.payload;
    },
    setIsSilentLoading: (state: any, action) => {
      state.isSilentLoading = action.payload;
    },
    setIsLoadingChat: (state: any, action) => {
      state.isLoadingChat = action.payload;
    },
    setIsRefreshLoading: (state: any, action) => {
      state.isRefreshLoading = action.payload;
    },
    setIsSearching: (state: any, action) => {
      state.isSearching = action.payload;
    },
    setError: (state: any, action) => {
      state.isError = action.payload;
    },
    setMessages: (state: any, action) => {
      state.messages = [];
    },
    setCurrentChatMessages: (state: any, action) => {
      if (Array.isArray(action.payload)) {
        state.currentChatMessages = action.payload;
      } else {
        const check = state.currentChatMessages.find((val: any) => {
          return val._id === action.payload._id;
        });
        if (!check) {
          state.currentChatMessages.unshift(action.payload);
        }
      }
    },
    setCurrentChat: (state: any, action) => {
      state.currentChat = {
        name: action.payload.name,
        conversationId: action.payload.conversationId,
        twilioConversationId: action.payload.twilioConversationId,
        chatTitle: action.payload.chatTitle,
        subject: action.payload.subject,
        patientDOB: action.payload?.patientDOB,
        participants: action.payload.participants,
      } as ICurrentChat;
    },
    clearCurrentChat: (state: any) => {
      state.currentChat = {
        name: "",
        conversationId: -1,
        twilioConversationId: "",
        participants: [],
        subject: "",
        patientDOB: null,
      };
    },
    resetPatientInConversation: (state: any) => {
      state.patientInConversation = null;
    },
    setOpenMessagingParticipantsPopup: (state: any, action) => {
      state.openMessagingParticipantsPopup = action.payload;
    },
    resetMessages: () => initialState,
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(
        requestFetchAllContactsAsync.pending,
        (state: any, action) => {
          state.isLoadingContacts = true;
        }
      ),
      builder.addCase(
        requestFetchAllContactsAsync.fulfilled,
        (state: any, action: any) => {
          state.isLoadingContacts = false;
          state.allContacts = action.payload.allContact ?? [];
        }
      ),
      builder.addCase(
        requestGetMessageLogsAsync.pending,
        (state: any, action) => {
          if (state.messages.length > 0) {
            state.isLoading = false;
            state.isSilentLoading = true;
          } else {
            state.isLoading = true;
            state.isSilentLoading = false;
          }
          state.isError = false;
        }
      ),
      builder.addCase(
        requestGetMessageLogsAsync.fulfilled,
        (state: any, action: any) => {
          const lastLength = state.messages.length;
          if (state.isSilentLoading && !state.isRefreshLoading) {
            state.messages = [
              ...state.messages,
              ...action.payload.conversationDetails,
            ];
          } else {
            state.messages = action.payload?.conversationDetails ?? [];
          }
          state.hasMoreMessages =
            lastLength !== state.messages.length &&
            state.messages.length % PAGE_LIMIT === 0;
          state.isLoading = false;
          state.isSilentLoading = false;
          state.isError = false;
          state.isRefreshLoading = false;
        }
      ),
      builder.addCase(
        requestGetMessageLogsAsync.rejected,
        (state: any, action) => {
          if (!action.meta.aborted) {
            state.isLoading = false;
            state.isError = true;
            state.isRefreshLoading = false;
            state.isSilentLoading = false;
          }
        }
      ),
      builder.addCase(requestcreateConversationAsync.pending, (state: any) => {
        state.isLoadingChat = true;
      }),
      builder.addCase(
        requestcreateConversationAsync.fulfilled,
        (state: any, action: any) => {
          state.isLoadingChat = false;
          state.isError = false;
          state.currentChat.name = action.payload.name;
          state.currentChat.conversationId = action.payload.conversationID;
          state.currentChat.twilioConversationId =
            action.payload.conversationTwilioID;
          state.currentChat.participants = [];
          state.currentChat.subject = action.payload.subject;
          state.currentChat.patientDOB = action.payload.patientDOB ?? null;
        }
      ),
      builder.addCase(
        requestcreateConversationAsync.rejected,
        (state: any, action) => {
          state.isLoadingChat = false;
          state.isError = true;
          const err = (action.payload as any)?.data?.error;
          toast.error(err, {
            toastId: "create-conversation-error",
          });
        }
      ),
      builder.addCase(
        requestGetConversationIfPresentAsync.pending,
        (state: any, action) => {
          state.isLoadingChat = true;
          state.isError = false;
        }
      ),
      builder.addCase(
        requestGetConversationIfPresentAsync.fulfilled,
        (state: any, action: any) => {
          state.isLoadingChat = false;
          state.isError = false;
          if (action.payload) {
            state.currentChat.name = action.payload.name;
            state.currentChat.conversationId = action.payload.conversationID;
            state.currentChat.twilioConversationId =
              action.payload.conversationTwilioID;
            state.currentChat.participants = [];
            state.currentChat.subject = action.payload.subject;
            state.currentChat.patientDOB = action.payload.patientDOB ?? null;
          }
        }
      ),
      builder.addCase(
        requestGetConversationIfPresentAsync.rejected,
        (state: any, action) => {
          state.isLoadingChat = false;
          state.isError = true;
        }
      ),
      builder.addCase(
        getPatientDetailsForConversationAsync.pending,
        (state, action) => {
          state.isLoadingPatientDetailsInConversation = true;
        }
      ),
      builder.addCase(
        getPatientDetailsForConversationAsync.fulfilled,
        (state, action) => {
          state.isLoadingPatientDetailsInConversation = false;
          if (action.payload) {
            state.patientInConversation = {
              ...action.payload,
              dob: action.payload.dob
                ? moment(action.payload.dob).format("MM/DD/YYYY")
                : "",
              procedureDate: action.payload.procedureDate
                ? moment(action.payload.procedureDate).format("MM/DD/YYYY")
                : "",
            };
          }
        }
      ),
      builder.addCase(
        getPatientDetailsForConversationAsync.rejected,
        (state) => {
          state.isLoadingPatientDetailsInConversation = false;
        }
      )
    );
  },
});

export default messagesSlice.reducer;
export const {
  setIsLoading,
  setIsSilentLoading,
  setIsSearching,
  setError,
  setMessages,
  setCurrentChat,
  clearCurrentChat,
  setCurrentChatMessages,
  resetMessages,
  setIsRefreshLoading,
  setIsLoadingChat,
  setOpenMessagingParticipantsPopup,
  resetPatientInConversation,
} = messagesSlice.actions;
export const hasMoreMessages = (state: any) => state.hasMoreMessages;
export const getMessages = (state: any): IMessagesState => state.messages;
