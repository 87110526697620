import { PAGE_LIMIT } from "../../shared/assets/constants/commonConstants";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { MessageRequestProps } from "../../shared/types/search-message-request";
import {
  getMessages,
  resetMessages,
  setCurrentChat,
  setIsRefreshLoading,
  setIsSearching,
  setIsSilentLoading,
  setMessages,
} from "../../state/features/messages/messages.slice";
import Header from "components/Header";
import SearchBox from "components/search-box/search-box.component";
import Button from "components/Button";
import { requestGetMessageLogsAsync } from "state/features/messages/messages.action";
import { getAuth } from "state/features/authentication/authentication.slice";
import "./messages.styles.scss";
import {
  getCommon,
  setIsChatScreenVisible,
} from "state/features/common/common.slice";
import { ComposeSidePopup } from "components/side-popups/ComposeMessageSidePopup/compose-message-side-popup.component";
import { debounce } from "lodash";
import { MessageOpenFrom, SortingOrderType } from "shared/types/enum";
import MessageLogRow from "components/Table/Presentation/messages/messages-row.component";
import { MessageLogProps } from "components/Table/props";
import CommonTable from "components/Table/CommonTable/common-table.component";
import { EmptyStateType } from "components/empty-state/empty-state.enum";
import ConversationParticipantsSidePopup from "components/side-popups/ConversationParticipantsPopup/conversation-participants-popup.component";
import { ICurrentChat, IMessage } from "state/types/messages-slice.type";

export type HeaderColumnInfo = {
  key: string;
  name: string;
  sortOrder?: SortingOrderType;
};

export function Messages() {
  const MESSAGE_LOGS_COLUMNS = [
    {
      name: "Participants",
      key: "participants",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      name: "Subject",
      key: "subject",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      name: "Date/Time",
      key: "conversationTime",
      sortOrder: SortingOrderType.DESC,
    },
    {
      name: "Message",
      key: "message",
    },
    {
      name: "",
      key: "actions",
    },
  ];
  const dispatch = useDispatch();
  const messagesState = useSelector(getMessages);
  const { isChatScreenVisible } = useSelector(getCommon);
  const authState = useSelector(getAuth);
  const [headerColumnsInfo, setHeaderColumns] =
    useState<HeaderColumnInfo[]>(MESSAGE_LOGS_COLUMNS);
  const [messagePayload, setMessagePayload] = useState<MessageRequestProps>({
    userId: authState.user.id,
    limit: PAGE_LIMIT,
    searchKeyword: "",
    offset: 0,
    sortColumn: "conversationtime",
    sortOrder: "desc",
  });
  const abortControllerForFetchMessagesAsync = useRef<() => void>();
  const [searchText, setSearchText] = useState<string>("");

  const fetchAllMessageConversations = useCallback(() => {
    dispatch(requestGetMessageLogsAsync(messagePayload));
  }, [dispatch, messagePayload]);

  useEffect(() => {
    let controller = new AbortController();
    return () => {
      controller?.abort();
      dispatch(resetMessages());
    };
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    fetchAllMessageConversations();
    return () => {
      controller?.abort();
    };
  }, [messagePayload, fetchAllMessageConversations]);

  const handleSearchInput = useCallback((value: string) => {
    dispatch(setMessages([]));
    setMessagePayload((prev) => {
      return {
        ...prev,
        searchKeyword: value.toLowerCase(),
        offset: 0,
      };
    });
  }, []);

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchInput, 500);
  }, [handleSearchInput]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const fetchMoreMessages = () => {
    setMessagePayload((prev) => {
      return {
        ...prev,
        offset: prev.offset + PAGE_LIMIT,
      };
    });
  };

  const refreshMessagesList = () => {
    batch(() => {
      dispatch(setIsRefreshLoading(true));
      dispatch(setIsSilentLoading(false));
    });
    dispatch(requestGetMessageLogsAsync(messagePayload));
  };

  const changeSort = (key: string, order: SortingOrderType) => {
    setMessagePayload((prev) => {
      return {
        ...prev,
        offset: 0,
        sortColumn: key.toLowerCase(),
        sortOrder: order.toLowerCase(),
      };
    });
  };

  const handleSorting = (selectedColumn: HeaderColumnInfo) => {
    dispatch(setMessages([]));
    const index = headerColumnsInfo.findIndex(
      (x) => x.key === selectedColumn.key
    );
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (headerColumnsInfo[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...headerColumnsInfo];
    tempHeaders.forEach((header) => {
      if (header.name !== "" && header.sortOrder) {
        header.sortOrder = SortingOrderType.DEFAULT;
      }
    });
    tempHeaders[index].sortOrder = sort;
    changeSort(selectedColumn.key, sort);
    setHeaderColumns(tempHeaders);
  };

  const getMessageColumnData = () => {
    return messagesState.messages.map(
      ({
        name,
        lastMessageDateTime,
        lastMessageContent,
        conversationID,
        twilioConversationId,
        type,
        subject,
        patientDOB,
        lastMessageSenderId,
        participantDetails,
      }: IMessage) =>
        ({
          participants: participantDetails,
          chatTitle: name,
          dateTime: lastMessageDateTime,
          message: lastMessageContent,
          patientDOB,
          conversationId: conversationID,
          twilioConversationId: twilioConversationId,
          type: type,
          subject,
          lastMessageSenderId: lastMessageSenderId,
        } as MessageLogProps)
    );
  };

  const renderMessagesColumnData = () => {
    return (
      <>
        {getMessageColumnData().map((item: MessageLogProps) => {
          return (
            <MessageLogRow
              key={item.conversationId}
              item={item}
              isReplyButtonDisabled={false}
              getAllMessages={refreshMessagesList}
              handleOpenMessage={(currentChat: ICurrentChat) => {
                dispatch(setCurrentChat(currentChat));
                dispatch(
                  setIsChatScreenVisible({
                    isOpen: true,
                    openFrom: MessageOpenFrom.EMPTY,
                  })
                );
              }}
            />
          );
        })}
      </>
    );
  };

  const renderMessages = () => {
    return (
      <div className="messages-table">
        <CommonTable
          currentTableData={messagesState.messages}
          columns={headerColumnsInfo}
          handleSort={handleSorting}
          isLoading={messagesState.isLoading}
          isInifiniteLoading={messagesState.isSilentLoading}
          changePageOffset={fetchMoreMessages}
          messageForNoData={EmptyStateType.NO_MESSAGES}
          canFetchMoreEpisodes={() => messagesState.hasMoreMessages}
          data={renderMessagesColumnData}
        />
      </div>
    );
  };

  return (
    <div id="messages">
      <ConversationParticipantsSidePopup></ConversationParticipantsSidePopup>
      <ComposeSidePopup
        isOpen={isChatScreenVisible.isOpen}
        onClose={() => {
          fetchAllMessageConversations();
        }}
        openFrom={isChatScreenVisible.openFrom}
      />
      <Header className="messages">
        <div className="heading">Messages</div>
        <div className="right-section">
          <div className="search-box-container">
            <SearchBox
              placeholder="Search by participant/keyword"
              icon="cross"
              text={searchText}
              className={`search-box ${
                messagesState.isSearching ? "opacity1" : "opacity0"
              }`}
              iconClassName="search-icon"
              SearchBoxSize={22}
              onClear={() => {
                if (messagePayload.searchKeyword) {
                  setSearchText("");
                  handleSearchInput("");
                  dispatch(setMessages([]));
                }
                dispatch(setIsSearching(false));
              }}
              onClick={() => {
                setSearchText("");
                handleSearchInput("");
                dispatch(setMessages([]));
                dispatch(setIsSearching(false));
              }}
              suggestionVisible
              onTextChange={(value: string) => {
                if (abortControllerForFetchMessagesAsync.current) {
                  abortControllerForFetchMessagesAsync.current();
                }
                setSearchText(value);
                debouncedSearch(value);
              }}
            />

            <Button
              icon="search"
              text="Search"
              showToolTip
              className="search-icon-container"
              iconClassName="search-icon"
              buttonSize={14}
              onClick={() => dispatch(setIsSearching(true))}
            />
          </div>
          {/* <Button
            icon="filter"
            className="filter-icon-container"
            iconClassName="filter-icon"
            buttonSize={14}
            onClick={() => {}}
          /> */}
          <button
            onClick={() => {
              dispatch(
                setIsChatScreenVisible({
                  isOpen: true,
                  openFrom: MessageOpenFrom.COMPOSE,
                })
              );
            }}
            className="green-button add-patient"
          >
            <span className="login-button-screen">Compose</span>
          </button>
        </div>
      </Header>
      {renderMessages()}
    </div>
  );
}
