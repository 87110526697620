import SidePopup from "components/Modal/SidePopup";
import "./compose-message-side-popup.styles.scss";

import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCurrentChat,
  getMessages,
  resetMessages,
  resetPatientInConversation,
  setCurrentChatMessages,
} from "state/features/messages/messages.slice";
import { useAppDispatch } from "state/store";
import ChatModalContent from "../../chat/modal/chat-modal.component";
import {
  getPatientFullName,
  isEmptyString,
} from "shared/methods/utilityFunctions";
import { MessagesSubjectType } from "shared/types/enum";
import { setIsChatScreenVisible } from "state/features/common/common.slice";
import { CustomToolTip } from "components/tool-tip/tool-tip.component";
import { ISelectedDropdownValue } from "components/chat/components/compose-content/types";
import { ComposeSidePopupProps } from "../props";
import { GENERAL } from "shared/constant/constants";
import { getPatientDetailsForConversationAsync } from "state/features/messages/messages.action";

export const ComposeSidePopup = (props: ComposeSidePopupProps) => {
  const { isOpen, onClose, openFrom } = props;
  const { currentChat, patientInConversation } = useSelector(getMessages);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [conversation, setConversation] = useState<any>(null);
  const [selectedParticipantsForChat, setSelectedParticipantsForChat] =
    useState<ISelectedDropdownValue[]>([]);
  const [showPatientDetails, setShowPatientDetails] = useState(false);

  useEffect(() => {
    if (
      isOpen &&
      currentChat.conversationId &&
      currentChat.conversationId !== -1 &&
      currentChat.subject.toLowerCase() !== GENERAL.toLowerCase()
    ) {
      appDispatch(
        getPatientDetailsForConversationAsync({
          conversationId: currentChat.conversationId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res) {
            setShowPatientDetails(true);
          }
        });
    }

    return () => {
      dispatch(resetPatientInConversation());
      setShowPatientDetails(false);
    };
  }, [currentChat.conversationId, currentChat, appDispatch, dispatch]);

  const getPatientDOB = () => {
    if (
      conversation &&
      conversation.patientDOB &&
      !isEmptyString(conversation.patientDOB) &&
      conversation.subject.toLowerCase() !==
        MessagesSubjectType.GENERAL.toLowerCase()
    ) {
      return `(${moment(conversation.patientDOB).format("MM/DD/YYYY")})`;
    } else if (
      currentChat &&
      currentChat.conversationId !== -1 &&
      currentChat.patientDOB &&
      currentChat.subject.toLowerCase() !==
        MessagesSubjectType.GENERAL.toLowerCase()
    ) {
      return `(${moment(currentChat.patientDOB).format("MM/DD/YYYY")})`;
    }

    return "";
  };

  const getHeading = () => {
    if (currentChat.conversationId !== -1 && currentChat.name) {
      const subject = conversation ? conversation.subject : currentChat.subject;

      return (
        <div className="two-liner-heading">
          <div
            className={`participants ${
              showPatientDetails ? "participants-with-patient" : ""
            }`}
            data-tip
            data-for={currentChat.name}
          >
            {currentChat.name}
            <CustomToolTip text={currentChat.name} place={"bottom"} />
          </div>
          {subject.toLowerCase() === GENERAL.toLowerCase() && (
            <div className="subject">
              Sub:{" "}
              <div className="subject-name" data-tip data-for={subject}>
                {subject}
                <CustomToolTip text={subject} place="bottom" />
              </div>{" "}
              {getPatientDOB()}
            </div>
          )}
          {showPatientDetails && (
            <div className="patient-details">
              <div className="patient-name">
                <label>Patient</label>:{" "}
                {getPatientFullName({
                  firstName: patientInConversation?.firstName,
                  lastName: patientInConversation?.lastName,
                })}
              </div>
              <div className="patient-dob">
                <label>Patient DOB</label>: {patientInConversation?.dob}
              </div>
              <div className="procedure">
                <label>Procedure</label>:{" "}
                {!!patientInConversation?.procedureName
                  ? patientInConversation?.procedureName
                  : "-"}
              </div>
              <div className="procedure-date">
                <label>Procedure Date</label>:{" "}
                {!!patientInConversation?.procedureDate
                  ? patientInConversation?.procedureDate
                  : "-"}
              </div>
            </div>
          )}
        </div>
      );
    }
    return "New Message";
  };

  const handleSidePopupClose = () => {
    setSelectedParticipantsForChat([]);
    appDispatch(setCurrentChatMessages([]));
    appDispatch(
      setIsChatScreenVisible({
        isOpen: false,
      })
    );
    appDispatch(resetMessages());
    onClose();
  };

  const clearDetails = () => {
    appDispatch(setCurrentChatMessages([]));
    appDispatch(clearCurrentChat());
  };

  const addItemInSelectedList = (item: Array<ISelectedDropdownValue>) => {
    clearDetails();
    setSelectedParticipantsForChat(item);
  };

  return (
    <SidePopup
      isOpen={isOpen}
      onClose={() => {
        handleSidePopupClose();
      }}
      heading={getHeading()}
      className="compose-side-popup"
      showPatientDetails={showPatientDetails}
    >
      <ChatModalContent
        addItemInSelectedList={addItemInSelectedList}
        selectedList={selectedParticipantsForChat}
        setConversation={setConversation}
        openFrom={openFrom}
        isOpen={isOpen}
      />
    </SidePopup>
  );
};
